<template>
  <header>
    <div class="left-side">
      <span class="logo-box-text">Gudolina studio</span>
    </div>
    <div class="right-side">
      <div
        v-if="isAuthenticated"
        id="header-user-wrapper"
        aria-haspopup="true"
        aria-controls="user_header_dropdown"
        @click="toggleHeaderUserMenu"
      >
        <!--        <span class="pi pi-fw pi-bell" />-->

        <div id="header-user">
          <AppAvatar :user="user" />
          <span>{{ displayName }}</span>
          <span class="pi pi-fw pi-chevron-down" />
        </div>
        <Menu id="user_header_dropdown" ref="user_header_dropdown" :model="userBar" :popup="true" />
      </div>
    </div>
  </header>
</template>

<script>
import AppAvatar from '@/components/Avatars/AppAvatar.vue'
import TabMenu from 'primevue/tabmenu'
import MenuBar from 'primevue/menubar'
import Menu from 'primevue/menu'
import Button from 'primevue/button'

export default {
  name: 'Header',
  components: {
    AppAvatar,
    TabMenu,
    MenuBar,
    Button,
    Menu
  },
  data() {
    return {
      userBar: [
        {
          items: [
            {
              label: 'Profile',
              icon: 'pi pi-user',
              command: () => {
                this.$router.push({ name: 'profile' })
              },
            },
            {
              label: 'Logout',
              icon: 'pi pi-fw pi-power-off',
              command: () => {
                this.logout()
              }
            }
          ]
        }
      ]
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated']
    },
    user() {
      return this.$store.getters['user/me']
    },
    displayName() {
      if (this.$store.getters['user/me'] !== null) {
        return this.$store.getters['user/me'].email
      }

      return ''
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    toggleHeaderUserMenu(event) {
      if (this.$refs.user_header_dropdown) {
        this.$refs.user_header_dropdown.toggle(event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@css/colorpalette.scss';

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  z-index: 999;
  color: white;
  background: $Primary40;
}

.left-side {
  margin-left: 4rem;
}

.right-side {
  margin-right: 4rem;
}

.logo-box-text {
  font-size: 1.5rem;
  font-weight: 700;
}

#nav-tabs-wrapper {
  position: absolute;
  height: 64px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

#header-user-wrapper {
  //position: absolute;
  //width: 112px;
  height: 64px;
  right: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

#header-user {
  display: flex;
  align-items: center;
  gap: 16px;
}
.avatar {
  width: 40px;
  height: 40px;
}
#user_header_dropdown {
  position: absolute;
  top: 64px !important;
  right: 0px;
  left: unset !important;
}

#login-buttons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 32px;
  width: 204px;
}
::v-deep .p-submenu-header {
  padding: 0 !important;
}
</style>
