// import Vue from 'vue'import
// import * as api from '../../api'

export default {
  namespaced: true,
  state: {
    dates: [
      '21.06',
      '22.06',
      '23.06',
      '24.06',
      '25.06',
      '26.06',
      '27.06',

      '28.06',
      '29.06',
      '30.06',
      '01.07',
      '02.07',
      '03.07',
      '04.07'
    ],
    models: [
      {
        name: 'Jessica',
        dates: {
          21.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          22.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          23.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          24.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          25.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          26.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          27.06: { time: { hours: 10, minutes: 15 }, income: 200 },

          28.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          29.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          30.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          '01.07': { time: { hours: 10, minutes: 15 }, income: 200 },
          '02.07': { time: { hours: 10, minutes: 15 }, income: 200 },
          '03.07': { time: { hours: 10, minutes: 15 }, income: 200 },
          '04.07': { time: { hours: 10, minutes: 15 }, income: 200 }
        },
        period_total: { time: { hours: 143, minutes: 25 }, income: 5000, salary: 4000 },
        remote_type: 'home',
        period_category: 'Newbie',
        qualified_category: 'Newbie',
        past_categories: [],
        avance: null
      },
      {
        name: 'Susan',
        dates: {
          21.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          22.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          23.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          24.06: { time: { hours: 10, minutes: 55 }, income: 200 },
          25.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          26.06: { time: { hours: 10, minutes: 55 }, income: 202 },
          27.06: { time: { hours: 10, minutes: 15 }, income: 200 },

          28.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          29.06: { time: { hours: 10, minutes: 15 }, income: 240 },
          30.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          '01.07': { time: { hours: 10, minutes: 15 }, income: 200 },
          '02.07': { time: { hours: 10, minutes: 15 }, income: 200 },
          '03.07': { time: { hours: 10, minutes: 35 }, income: 200 },
          '04.07': { time: { hours: 10, minutes: 15 }, income: 200 }
        },
        period_total: { time: { hours: 143, minutes: 25 }, income: 5000, salary: 4000 },
        remote_type: 'studio',
        period_category: 'Star',
        qualified_category: 'Good',
        past_categories: ['Good', 'Good'],
        avance: 400
      },
      {
        name: 'Samantha',
        dates: {
          21.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          22.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          23.06: { time: { hours: 10, minutes: 45 }, income: 200 },
          24.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          25.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          26.06: { time: { hours: 10, minutes: 25 }, income: 200 },
          27.06: { time: { hours: 10, minutes: 15 }, income: 200 },

          28.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          29.06: { time: { hours: 10, minutes: 15 }, income: 200 },
          30.06: { time: { hours: 10, minutes: 25 }, income: 200 },
          '01.07': { time: { hours: 10, minutes: 15 }, income: 200 },
          '02.07': { time: { hours: 10, minutes: 15 }, income: 200 },
          '03.07': { time: { hours: 10, minutes: 25 }, income: 500 },
          '04.07': { time: { hours: 10, minutes: 15 }, income: 200 }
        },
        period_total: { time: { hours: 143, minutes: 25 }, income: 5000, salary: 4000 },
        remote_type: 'home',
        period_category: 'Superstar',
        qualified_category: 'Good',
        past_categories: ['Good', 'Star'],
        avance: 300
      }
      // {
      //   name: 'Traktorina',
      //   dates: {
      //     '21.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '22.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '23.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '24.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '25.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '26.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '27.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //
      //     '28.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '29.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '30.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '01.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '02.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '03.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '04.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //   },
      //   period_income: 5000,
      //   remote_type: 'studio',
      //   period_category: 'Good',
      //   qualified_category: 'Good',
      //   past_categories: ['Star', 'Star'],
      //   avance: 500,
      // },
      // {
      //   name: 'Roxanne',
      //   dates: {
      //     '21.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '22.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '23.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '24.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '25.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '26.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '27.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //
      //     '28.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '29.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '30.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '01.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '02.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '03.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '04.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //   },
      //   period_income: 5000,
      //   remote_type: 'home',
      //   period_category: 'Top',
      //   qualified_category: 'Top',
      //   past_categories: ['Top', 'Top'],
      //   avance: 200,
      // },
      // {
      //   name: 'Proskovya',
      //   dates: {
      //     '21.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '22.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '23.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '24.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '25.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '26.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '27.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //
      //     '28.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '29.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '30.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '01.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '02.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '03.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '04.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //   },
      //   period_income: 5000,
      //   remote_type: 'studio',
      //   period_category: 'Top',
      //   qualified_category: 'Newbie',
      //   past_categories: ['Newbie', 'Newbie'],
      //   avance: 600,
      // },
      // {
      //   name: 'Gurza',
      //   dates: {
      //     '21.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '22.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '23.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '24.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '25.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '26.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '27.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //
      //     '28.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '29.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '30.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '01.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '02.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '03.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '04.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //   },
      //   period_income: 5000,
      //   remote_type: 'home',
      //   period_category: 'Star',
      //   qualified_category: 'Star',
      //   past_categories: ['Good', 'Star'],
      //   avance: 700,
      // },
      // {
      //   name: 'Zulfia',
      //   dates: {
      //     '21.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '22.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '23.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '24.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '25.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '26.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '27.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //
      //     '28.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '29.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '30.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '01.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '02.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '03.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '04.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //   },
      //   period_income: 5000,
      //   remote_type: 'studio',
      //   period_category: 'Star',
      //   qualified_category: 'Star',
      //   past_categories: ['Good', 'Star'],
      //   avance: 700,
      // },
      // {
      //   name: 'Kristin',
      //   dates: {
      //     '21.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '22.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '23.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '24.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '25.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '26.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '27.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //
      //     '28.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '29.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '30.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '01.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '02.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '03.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '04.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //   },
      //   period_income: 5000,
      //   remote_type: 'home',
      //   period_category: 'Star',
      //   qualified_category: 'Star',
      //   past_categories: ['Good', 'Star'],
      //   avance: 700,
      // },
      // {
      //   name: 'Aglafia',
      //   dates: {
      //     '21.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '22.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '23.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '24.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '25.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '26.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '27.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //
      //     '28.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '29.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '30.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '01.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '02.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '03.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '04.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //   },
      //   period_income: 5000,
      //   remote_type: 'studio',
      //   period_category: 'Star',
      //   qualified_category: 'Star',
      //   past_categories: ['Good', 'Star'],
      //   avance: 700,
      // },
      // {
      //   name: 'Karen',
      //   dates: {
      //     '21.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '22.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '23.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '24.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '25.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '26.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '27.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //
      //     '28.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '29.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '30.06': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '01.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '02.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '03.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //     '04.07': { time: { hours: 10, minutes: 15, }, income: 200 },
      //   },
      //   period_income: 5000,
      //   remote_type: 'home',
      //   period_category: 'Star',
      //   qualified_category: 'Star',
      //   past_categories: ['Good', 'Star'],
      //   avance: 700,
      // },
    ],
    categories: [
      {
        title: 'Newbie',
        rate_modifier: 0.0,
        qualification: {
          max_income: 1500
        }
      },
      {
        title: 'Progressive',
        rate_modifier: 0.03,
        qualification: {
          max_income: 1500,
          min_hours: 85
        }
      },
      {
        title: 'Good',
        rate_modifier: 0.05,
        qualification: {
          min_income: 1500,
          min_hours: 60
        }
      },
      {
        title: 'Top',
        rate_modifier: 0.07,
        qualification: {
          min_income: 3000,
          min_hours: 80
        }
      },
      {
        title: 'Star',
        rate_modifier: 0.1,
        qualification: {
          min_income: 5000
        }
      },
      {
        title: 'Superstar',
        rate_modifier: 0.15,
        qualification: {
          min_income: 10000,
          min_hours: 80
        }
      }
    ],
    remote_types: [
      {
        title: 'home',
        value: 0.6
      },
      {
        title: 'studio',
        value: 0.5
      }
    ]
  },
  getters: {
    dates: (state) => state.dates,
    models: (state) => state.models,
    categories: (state) => state.categories,
    remote_types: (state) => state.remote_types
  }
}
