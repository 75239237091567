import axios from '../../axios'
import * as api from '../../api'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    performersPeriodSalaries: {}
  },
  getters: {
    periodSalary: (state) => (periodReportId) => {
      return state.performersPeriodSalaries
        ? _.filter(state.performersPeriodSalaries, (item) => {
            return +item.period_report.id === +periodReportId
          })[0]
        : null
    }
  },
  actions: {
    fetchPerformerPeriodSalary({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(api.default.salary.performerPeriod(payload.id))
          .then((response) => {
            commit('SET_PERFORMER_PERIOD_SALARY', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    postSalary({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(api.default.salary.postSalary, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    putSalary({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(api.default.salary.putSalary(payload.salary_id), payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    sendSalarySheet({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(api.default.salary.sendSalarySheet(payload.salary_id))
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateSalaryPaidAt({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(api.default.salary.updatePaidAtDate(payload.salary_id))
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  mutations: {
    SET_PERFORMER_PERIOD_SALARY: (state, data) => {
      state.performersPeriodSalaries[data.period_report.id] = data
    }
  }
}
