import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

// LAYOUTS
import LayoutGuest from '@/layouts/LayoutGuest.vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'

const ifAuthenticated = (to, from, next) => {
  if (store.getters['user/isAuthenticated']) {
    next()
  } else {
    // next('/login?forbidden=1')
    next('/login')
  }
}

const routes = [
  // AUTH
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Auth/Login.vue'),
    meta: {
      layout: LayoutGuest
    }
  },
  {
    path: '/password-reset-form',
    name: 'password-reset-form',
    component: () => import('@/pages/Auth/PasswordResetForm.vue'),
    meta: {
      layout: LayoutGuest
    }
  },
  {
    path: '/password-reset-update',
    name: 'password-reset-update',
    component: () => import('@/pages/Auth/PasswordResetUpdate.vue'),
    meta: {
      layout: LayoutGuest
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/pages/Auth/Register.vue'),
    meta: {
      layout: LayoutGuest
    }
  },
  {
    path: '/password-init',
    name: 'password-init',
    component: () => import('@/pages/Auth/PasswordInitSetup.vue'),
    meta: {
      layout: LayoutGuest
    }
  },

  // MAIN PAGES
  {
    path: '/dashboard',
    alias: '/',
    name: 'dashboard',
    component: () => import('@/pages/Dashboard.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/periods',
    alias: '/',
    name: 'periods',
    component: () => import('@/pages/Periods.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/periods/:periodId/performer/:performerId/salary',
    name: 'period-performer-salary',
    props: true,
    component: () => import('@/pages/Periods/PerformerSalary.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/pages/Users.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/users/:id',
    props: true,
    name: 'user-view',
    component: () => import('@/pages/Users/UserPage.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/users/:id/edit',
    props: true,
    name: 'user-edit',
    component: () => import('@/pages/Users/UserForm.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/users/create',
    props: true,
    name: 'user-create',
    component: () => import('@/pages/Users/NewUserPage.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/pages/Categories.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/categories/:id',
    name: 'category-view',
    props: true,
    component: () => import('@/pages/Categories/View.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/categories/:id/edit',
    name: 'category-edit',
    props: true,
    component: () => import('@/pages/Categories/Edit.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/categories/create',
    name: 'category-create',
    component: () => import('@/pages/Categories/Create.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/pages/Calendar.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('@/pages/Tasks.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/tasks/new',
    name: 'task-new',
    props: true,
    component: () => import('@/pages/Tasks.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/tasks/:id',
    name: 'task',
    props: true,
    component: () => import('@/pages/Tasks.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import('@/pages/Stats.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/files',
    name: 'files',
    component: () => import('@/pages/Files.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/pages/Settings.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/jasmin-integration',
    name: 'jasmin-integration',
    component: () => import('@/pages/JasminIntegration.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/pages/Profile.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LayoutDefault
    }
  },

  // 403
  {
    path: '/403',
    name: 'forbidden',
    component: () => import('@/pages/Forbidden403.vue')
  },
  // 404
  {
    path: '/404',
    name: 'notfound',
    component: () => import('@/pages/NotFound404.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  routes
})

export default router
