<template>
  <div id="app">
    <component :is="$route.meta.layout || 'div'">
      <router-view />
    </component>

    <Toast position="bottom-right" />
  </div>
</template>

<script>
import Toast from 'primevue/toast'
import '@css/app.scss'

export default {
  name: 'App',
  components: {
    Toast
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Raleway', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.p-component {
  font-family: 'Montserrat', 'Raleway', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
</style>
