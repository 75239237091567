import * as api from '@/api'
import axios from '@/axios'

export default {
  namespaced: true,
  state: {
    list: []
  },
  getters: {
    list: (state) => state.list,
  },
  actions: {
    async fetchList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url
        if (payload) {
          const params = new URLSearchParams(payload);
          url = `${api.default.tasks.list}?${params}`
        } else {
          url = api.default.tasks.list
        }

        axios.get(url)
          .then((response) => {
            commit('SET_LIST', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    create({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(api.default.tasks.create, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    update({ commit, state }, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        axios.put(api.default.tasks.update(data.id), data.payload)
          .then((response) => {

            for (const status in state.list) {
              const index = state.list[status].tasks.findIndex(model => model.id === data.id);
              if (index !== -1) {
                const payload = { ...state.list[status].tasks[index], ...data.payload };
                console.log(payload);
                state.list[status].tasks[index] = payload;
              }
            }

            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(api.default.tasks.delete(id))
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    reorder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(api.default.tasks.reorder, { data: payload })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
  mutations: {
    SET_LIST: (state, data) => {
      state.list =  data;
    },
  }
}
