<template>
  <div v-if="user.avatar" class="editable-avatar-container">
    <Avatar v-if="user.avatar"
            :image="user.avatar"
            shape="circle"
            style="color: white !important"
    />
    <div v-if="editable" class="overlay" @click="openCropper">
      <i class="pi pi-camera edit-icon" @click="editAvatar"></i>
    </div>
  </div>

  <div v-else class="editable-avatar-container">
    <div class="avatar-letter" :style="letterSize" @click="openCropper">{{ letters }}</div>
    <div v-if="editable" class="overlay" @click="showCropper = true">
      <i class="pi pi-camera edit-icon" @click="editAvatar"></i>
    </div>
  </div>

  <avatar-cropper v-model="showCropper"
    :upload-handler="uploadAvatar"
    @changed="onFileSelected"
  />
</template>

<script>
import Avatar from 'primevue/avatar'
import AvatarCropper from 'vue-avatar-cropper'
import filesService from '@/services/filesService'

export default {
  name: 'AppAvatar',
  components: {
    Avatar,
    AvatarCropper,
  },
  props: {
    user: { required: true },
    big: { default: false },
    editable: { default: false },
  },
  data() {
    return {
      showCropper: false,
      newAvatarFile: null,
    }
  },
  computed: {
    letters() {
      if (this.user.first_name && this.user.last_name) {
        return this.user.first_name.substring(0, 1) + this.user.last_name.substring(0, 1)
      }
    },
    letterSize() {
      return this.big ? 'font-size: 49px' : null;
    }
  },
  methods: {
    openCropper() {
      if (this.editable) { this.showCropper = true }
    },
    onFileSelected(event) {
      this.newAvatarFile = event.file
    },
    uploadAvatar(event) {
      const base64 = event.getCroppedCanvas().toDataURL(this.newAvatarFile.type)
      const payload = {
        files: [filesService.base64toFile(base64, this.newAvatarFile.name)],
        name: this.newAvatarFile.name,
        mode: 'avatar',
      }

      filesService.uploadFiles(payload)
        .then(res => {
          this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 })
          this.$store.dispatch('user/fetchMe');
          console.log(res)
        })
        .catch(err => {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'File is not Uploaded', life: 3000 })
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar-letter {
  font-size: 16px;
  font-weight: 600;
  border-radius: 50%;
  border: 4px solid white;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-image: url('/images/ava_bg.png');
  background-size: cover;

  height: 50px;
  width: 50px;
  color: white;
}

.editable-avatar-container {
  position: relative;
  width: 50px;
  height: 50px;
}

.edit-icon {
  color: white;
  font-size: 20px;
}

.overlay {
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background-color: rgba(0,0,0,.2);

  &:hover {
    opacity: 1;
  }
}

:deep(.p-avatar) {
  position: relative;
}

:deep(.avatar-cropper-btn) {
  background-color: transparent;
  color: $Primary40;
  border: 1px solid;
  border-color: $Primary40;
  font-family: "Montserrat", "Raleway", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
}
</style>
