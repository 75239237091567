<template>
  <div>
    <Header />
    <div id="layout-body">
      <div class="sidebar-wrapper">
        <Sidebar />
      </div>
      <div class="content-wrapper">
        <Card>
          <template #content>
            <router-view></router-view>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue'
import Header from './components/Header.vue'
import Card from 'primevue/card'

export default {
  name: 'LayoutDefault',
  components: {
    Sidebar,
    Header,
    Card
  }
}
</script>

<style lang="scss" scoped>
@import '@css/colorpalette.scss';

#layout-body {
  margin-top: 4rem;
}
.sidebar-wrapper {
  float: left;
  width: 4rem;
  background: white;
}
.content-wrapper {
  float: left;
  width: calc(100% - 4rem);
  background: $Grey10;
  padding: 1rem;
  min-height: calc(100vh - 4rem);
}
</style>
