<template>
  <div class="sidebar-component">
    <div class="navigation">
      <div
        v-for="item in navigation.top"
        :key="item.title"
        @click="$router.push({ name: item.route })"
      >
        <div
            v-if="allowed(item)"
            :class="[pageActive(item) ? 'highlight' : '', 'element']"
        >
          <!--        <img v-if="pageActive(item.route)" :src="item.activeImg" alt="logo" />-->
          <!--        <img v-else :src="item.img" alt="logo" />-->
          <img :src="item.img" :alt="item.route" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Sidebar',
  data() {
    return {
      navigation: {
        top: [
          {
            route: 'dashboard',
            img: '/images/icons/v1/Display.svg',
            // activeImg: '/images/sidebar/Video-active.png',
            // title: 'Dashboard',
          },
          {
            route: 'periods',
            img: '/images/icons/v1/Video.svg'
            // activeImg: '/images/sidebar/Video-active.png',
            // title: 'Dashboard',
          },
          {
            route: 'users',
            img: '/images/icons/v1/Group.svg',
            children: [{ route: 'user-view' }, { route: 'user-create' }],
            allowed: ['admin','owner','manager'],
            // activeImg: '/images/sidebar/Group-active.png',
            // title: 'Companies',
          },
          {
            route: 'categories',
            img: '/images/icons/v1/Award4.svg'
            // activeImg: '/images/sidebar/Video-active.png',
            // title: 'Dashboard',
          },
          // {
          //   route: 'calendar',
          //   img: '/images/icons/v1/Calendar.svg',
          //   activeImg: '/images/sidebar/events-icon-active.png',
          //   // title: 'Companies',
          // },
          // {
          //   route: 'stats',
          //   img: '/images/icons/v1/Chart.svg',
          //   activeImg: '/images/sidebar/templates-icon-active.png',
          //   // title: 'Users',
          // },
          {
            route: 'tasks',
            img: '/images/icons/v1/Chart.svg',
            // activeImg: '/images/sidebar/templates-icon-active.png',
            // title: 'Users',
          },
          {
            route: 'files',
            img: '/images/icons/v1/Document.svg',
            // activeImg: '/images/sidebar/templates-icon-active.png',
            // title: 'Queue Manager',
          },
          // {
          //   route: 'settings',
          //   img: '/images/icons/v1/Cog.svg',
          //   activeImg: '/images/sidebar/help-icon-active.png',
          //   // title: 'Issues',
          // },
          // {
          //   route: 'jasmin-integration',
          //   img: '/images/icons/v1/lj.svg',
          //   activeImg: '/images/sidebar/help-icon-active.png',
          //   // title: 'Email Templates',
          // },
        ]
      }
    }
  },
  methods: {
    // pageActive(val) {
    //   return this.$route.name === val;
    // },
    pageActive(item) {
      if (this.$route.matched.some((route) => route.name === item.route)) {
        return true
      }

      if (item.children) {
        return item.children.some((child) =>
          this.$route.matched.some((route) => route.name === child.route)
        )
      }

      return false
    },
    allowed(item) {
      if (!item.allowed || item.allowed.length === 0) { return true; }

      return _.includes(item.allowed, this.me.role);
    },
  },
  computed: {
    me() {
      return this.$store.getters['user/me'];
    },
  },
}
</script>

<style scoped lang="scss">
@import '@css/colorpalette.scss';

.sidebar-component {
  font-family: Inter;
  height: 100%;
  position: relative;
}
.element {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 4rem;
  padding: 1rem;
}
.element:hover {
  cursor: pointer;
  background: $Primary40;
}
.highlight {
  //color: #f1105e;
  //background: #feebf2;
  background: $Primary40;
  //border-left: 4px solid #f50b5d;
}
</style>
