import { createApp } from 'vue'

import './bootstrap'
import 'bootstrap'
import '../css/app.css'

import router from './router'
import store from './store'

import App from './App.vue'
import VueCookies from 'vue-cookies'

import ToastService from 'primevue/toastservice'
import 'primevue/resources/themes/soho-light/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import PrimeVue from 'primevue/config'

const app = createApp(App)
app.use(VueCookies)
  .use(ToastService)
  .use(PrimeVue, {
    locale: {
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    }
  })
  .use(router)
  .use(store)

import mitt from 'mitt'
const emitter = mitt()
app.provide('eventBus', emitter)

import filters from './filters'
app.config.globalProperties.$filters = filters

import Calendar from 'primevue/calendar'
app.component('Calendar', Calendar)

import Button from 'primevue/button'
app.component('Button', Button)

import InputText from 'primevue/inputtext'
app.component('InputText', InputText)

app.mount('#app')
