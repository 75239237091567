import axios from 'axios'
import store from './store'
import router from './router'

// Headers
axios.defaults.headers.common.Accept = 'application/vnd.api+json'
axios.defaults.baseURL = import.meta.env.VITE_API_URL ? import.meta.env.VITE_API_URL : 'https://localhost'
axios.defaults.headers.common.ContentType = 'application/json'

// Interceptors
// Auto Bearer Authentication
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

// 422 AUTO TOAST MSGS || TODO
// axios.interceptors.response(
//   response => {
//     return response;
//   },
//     error => {
//       if (error.response && error.response.status === 422) {
//         // Laravel validation error response
//         const errors = error.response.data.errors;
//         for (let key in errors) {
//           // Display each error message
//           app.$toast.add({
//             severity: 'warn',
//             summary: 'Invalid form data',
//             detail: "Bla bla",
//             life: 5000,
//           });
//           // usePVToastService.add({
//           // })error(errors[key][0]);
//         }
//       }
//       return Promise.reject(error);
//     }
// );

// Auto token refresh
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (!localStorage.getItem('accessToken')) {
        if (router.currentRoute.path !== 'login') {
          await router.push({ name: 'main' })
        }
        return Promise.reject(error)
      }

      if (!store.getters['user/isRefreshOngoing']) {
        await store
          .dispatch('user/refreshToken')
          .then(() => {
            return axios(originalRequest)
          })
          .catch(() => {
            router.push({ name: 'main' })
          })
      } else {
        // If multiple requests other than first-401-failed request => wait for refresh event to fire
        await new Promise((resolve, reject) => {
          Vue.bus.once('tokenRefreshFinished', (eventPayload) => {
            if (eventPayload.success === true) {
              originalRequest._retry = true
              resolve(axios(originalRequest))
            } else {
              router.push({ name: 'main' })
              reject('Token refresh went bad')
            }
          })
        })
      }
    }

    return Promise.reject(error)
  }
)

function forceAuth() {
  window.EventBus.dispatchEvent('forceAuth')
}

axios.interceptors.response.use(
  (response) => {
    // Check for the desired status codes and handle accordingly
    if (response.status >= 400) {
      if (response.status !== 401) {
        return Promise.reject(response) // AUTO REJECT ALL ABOVE 400 except 401
      }
    }
    return response // Continue with the response
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Base URL
// axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default axios
