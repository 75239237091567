import axios from '../../axios'
import * as api from '../../api'

export default {
  namespaced: true,
  state: {
    periods: {
      all: [],
      selected: null,
      dates: null,
      models: null
    }
  },
  getters: {
    currentPeriodDates: (state) => state.periods.current.dates,
    allPeriods: (state) => state.periods.all,
    dates: (state) => state.periods.dates,
    models: (state) => {
      return state.periods.models
    },
    selectedPeriod: (state) => {
      if (!!state.periods.selected) {
        console.log('selected period from store')
        return state.periods.selected
      }
      if (
        !!localStorage.getItem('periodSelected') &&
        localStorage.getItem('periodSelected') !== 'undefined'
      ) {
        console.log('selected period from localstorage')
        return JSON.parse(localStorage.getItem('periodSelected'))
      }
      console.log('no selected period')
      return null
    }
  },
  actions: {
    fetchAllPeriods({ commit, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(api.default.period.all)
          .then((response) => {
            commit('SET_ALL_PERIODS', response)
            if (!getters.selectedPeriod) {
              dispatch('selectPeriod', response.data[0])
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchDatesForSelectedPeriod({ commit, getters }) {
      let periodId = getters.selectedPeriod ? getters.selectedPeriod.id : null
      if (!periodId) {
        return
      }

      return new Promise((resolve, reject) => {
        axios
          .get(api.default.period.dates(periodId))
          .then((response) => {
            commit('SET_SELECTED_PERIOD_DATES', response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchStatsForSelectedPeriod({ commit, getters }) {
      let periodId = getters.selectedPeriod ? getters.selectedPeriod.id : null
      if (!periodId) {
        return
      }

      return new Promise((resolve, reject) => {
        axios
          .get(api.default.period.table(periodId))
          .then((response) => {
            commit('SET_SELECTED_PERIOD_MODELS', response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    selectPeriod({ commit, dispatch }, value) {
      commit('SET_SELECTED_PERIOD', value)
      dispatch('fetchDatesForSelectedPeriod')
      dispatch('fetchStatsForSelectedPeriod')
    },
    refreshSelectedPeriod({ getters }) {
      return new Promise((resolve, reject) => {
        const periodId = getters.selectedPeriod.id

        axios
          .post(api.default.period.refresh(periodId))
          .then((response) => resolve(response))
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  mutations: {
    SET_SELECTED_PERIOD_DATES: (state, response) => {
      state.periods.dates = response.data
    },
    SET_SELECTED_PERIOD_MODELS: (state, response) => {
      state.periods.models = response.data
    },
    SET_ALL_PERIODS: (state, response) => {
      state.periods.all = response.data
    },
    SET_SELECTED_PERIOD: (state, selectedPeriod) => {
      localStorage.setItem('periodSelected', JSON.stringify(selectedPeriod))
      state.periods.selected = selectedPeriod
    }
  }
}
