import { createStore } from 'vuex'
import base from './store/modules/base'
import period from './store/modules/period'
import company from './store/modules/company'
import user from './store/modules/user'
import users from './store/modules/users'
import categories from './store/modules/categories'
import salary from './store/modules/salary'
import currency from './store/modules/currency'
import files from './store/modules/files'
import tasks from './store/modules/tasks'

const store = createStore({
  modules: {
    base,
    period,
    company,
    user,
    users,
    categories,
    salary,
    currency,
    files,
    tasks,
  }
})

export default store
