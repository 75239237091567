import * as api from '@/api'
import axios from '@/axios'

export default {
  namespaced: true,
  state: {
    users: null,
    performers: null,
    allUsers: null,
  },
  getters: {
    getList: (state) => state.users,
    getSingle: (state) => (id) => {
      return state.users ? state.users.filter((item) => +item.id === +id)[0].attributes : null
    },
    getPerformersList: (state) => {
      return state.users ? state.users.filter((item) => item.attributes.role === 'performer') : null
    },
    getAll: (state) => state.allUsers,
  },
  actions: {
    async fetchList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = api.default.users.fetchList

        axios
          .get(url)
          .then((response) => {
            commit('SAVE_USER_LIST', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchSingle({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = api.default.users.fetchSingle(payload.id)

        axios
          .get(url)
          .then((response) => {
            commit('SAVE_USER_SINGLE', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async create({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = api.default.users.create

        axios
          .post(url, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },
    async fetchUserJasPerformers({}, userId) {
      // TODO IMPLEMENT
      return new Promise((resolve, reject) => {
        const url = api.default.users.getPerformers(userId)

        axios
          .post(url)
          .then((response) => {
            // console.log(response)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },
    fetchAllUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(api.default.users.all)
          .then((response) => {
            commit('SET_ALL_USERS', response.data.data)
            // console.log(response)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    }
  },
  mutations: {
    SAVE_USER_LIST: (state, data) => {
      state.users = data.data
    },
    SAVE_USER_SINGLE: (state, fetchedUser) => {
      if (!state.users) {
        state.users = []
      }

      const index = state.users.findIndex((user) => user.id === fetchedUser.id)
      if (index !== -1) {
        state.users[index] = fetchedUser
      } else {
        state.users.push(fetchedUser)
      }
    },
    SET_ALL_USERS: (state, data) => {
      state.allUsers = data
    }
  }
}
