import * as api from '@/api'
import axios from '@/axios'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    list: (state) => state.list,
  },
  actions: {
    async upload({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios.post(api.default.files.upload, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      });
    },
    async fetchList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url
        if (payload) {
          const params = new URLSearchParams(payload);
          url = `${api.default.files.list}?${params}`
        } else {
          url = api.default.files.list
        }

        axios.get(url)
          .then((response) => {
            commit('SET_LIST', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    uploadCancel({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(api.default.files.uploadCancel(id))
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(api.default.files.delete(id))
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteForce({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(api.default.files.forceDelete(id))
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    recover({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(api.default.files.recover(id))
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
  mutations: {
    SET_LIST: (state, data) => {
      state.list = data;
    },
  }
}
