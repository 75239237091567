import * as api from '@/api'
import axios from '@/axios'

export default {
  namespaced: true,
  state: {
    list: null,
    performersPastCategories: {}
  },
  getters: {
    getList: (state) => state.list,
    getSingle: (state) => (id) => {
      return state.list ? state.list.filter((item) => +item.id === +id)[0] : null
    },
    performerPastCategories: (state) => (performerId) => {
      return state.performersPastCategories[performerId]
        ? state.performersPastCategories[performerId]
        : null
    }
  },
  actions: {
    async fetchList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = api.default.categories.fetchList

        axios
          .get(url)
          .then((response) => {
            commit('SAVE_LIST', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchSingle({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = api.default.categories.fetchSingle(payload.id)

        axios
          .get(url)
          .then((response) => {
            commit('SAVE_SINGLE', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async create({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = api.default.categories.create

        axios
          .post(url, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },
    async update({ commit }, data) {
      return new Promise((resolve, reject) => {
        const url = api.default.categories.update(data.id)

        axios
          .put(url, data.payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },
    async delete({ commit }, data) {
      return new Promise((resolve, reject) => {
        const url = api.default.categories.delete(data.id)

        axios
          .delete(url)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },
    async reorder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = api.default.categories.reorder

        axios
          .put(url, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },
    fetchPerformerPastCategories({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(api.default.categories.performerPastCategories(payload.id))
          .then((response) => {
            commit('SET_PERFORMER_PAST_CATEGORIES', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  mutations: {
    SAVE_LIST: (state, data) => {
      state.list = data.data
    },
    SAVE_SINGLE: (state, fetchedSingleItem) => {
      if (!state.list) {
        state.list = []
      }

      const index = state.list.findIndex((category) => category.id === fetchedSingleItem.id)
      if (index !== -1) {
        state.list[index] = fetchedSingleItem
      } else {
        state.list.push(fetchedSingleItem)
      }
    },
    SET_PERFORMER_PAST_CATEGORIES: (state, data) => {
      if (!data || data.length === 0) {
        return
      }
      state.performersPastCategories[data[0]['performer_id']] = data
    }
  }
}
