import axios from '../../axios'
import * as api from '../../api'

export default {
  namespaced: true,
  state: {
    localCurrencyRate: localStorage.getItem('localCurrencyRate') || 1
  },
  getters: {
    localCurrencyRate: (state) => state.localCurrencyRate
  },
  actions: {
    updateLocalRate({ state }, newRate) {
      localStorage.setItem('localCurrencyRate', newRate)
      state.localCurrencyRate = newRate
    }
  }
}
