import { Duration, DateTime } from 'luxon'
import moment from 'moment'

export default {
  secondsToHoursMinutes(seconds) {
    return Duration.fromObject({ seconds: seconds }).toFormat('hh:mm')
  },
  YmdToMd(dateString) {
    return moment(dateString, 'YYYY-MM-DD').format('MM.DD')
  },
  DMY(dateTimeString) {
    return moment(dateTimeString).format('DD.MM.YYYY')
  },
  YmdToDDMM(dateString) {
    return moment(dateString, 'YYYY-MM-DD').format('DD.MM')
  },
  formatDate(dateString) {
    return moment(dateString, 'YYYY-MM-DD H:i:s').format('DD.MM.YYYY hh:mm:ss')
  }
}
