import _ from 'lodash'
import store from '@/store';

export default {
  uploadFiles(payload) {
    const formData = new FormData();
    payload.files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    if (payload.mode) {
      formData.append('mode', payload.mode)
    }

    return store.dispatch('files/upload', formData)
  },
  blobToFile(blob) {
    console.log(blob)
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        const arrayBuffer = reader.result
        const fileType = blob.type
        const fileName = "converted-file." + fileType.split("/")[1]
        const convertedFile = new File([arrayBuffer], fileName, {type: fileType})
        resolve(convertedFile);
      };

      reader.onerror = reject
      reader.readAsArrayBuffer(blob)
    })
  },
  base64toFile(base64String, filename) {
    const parts = base64String.split(';base64,')
    const contentType = parts[0].split(':')[1]
    const base64Content = parts[1]

    // Decode base64 string using lodash
    const decodedContent = _.deburr(base64Content)

    // Convert to Uint8Array
    const binaryString = window.atob(decodedContent)
    const rawLength = binaryString.length
    const uInt8Array = new Uint8Array(rawLength)
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = binaryString.charCodeAt(i)
    }

    // Create Blob and File
    const blob = new Blob([uInt8Array], { type: contentType })
    return new File([blob], filename, { type: contentType })
  },
  async downloadFile(url, filename) {
    const response = await fetch(url)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
  bytesToMegabytes(bytes) {
    return (bytes / (1024 * 1024)).toFixed(1) + 'MB';
  },
  showFile(file) {
    window.open(file.url, '_blank');
  },
}
