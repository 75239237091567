import * as api from '@/api'
import axios from '@/axios'

export default {
  namespaced: true,
  state: {
    accessToken: localStorage.getItem('accessToken') || null,
    status: null,
    me: JSON.parse(localStorage.getItem('me')) || JSON.stringify({}),
    tokenRefreshOngoing: false
  },
  getters: {
    isAuthenticated: (state) => state.accessToken,
    isAdmin: (state) => state.me.role === 'ADMIN',
    authStatus: (state) => state.status,
    username: (state) => state.me.username,
    me: (state) => state.me,
    isRefreshOngoing: (state) => state.tokenRefreshOngoing,
    roleOptions: (state) => {
      if (state.me.role === 'owner') {
        return ['supervisor', 'manager', 'performer']
      }

      if (state.me.role === 'supervisor') {
        return ['manager', 'performer']
      }

      if (state.me.role === 'manager') {
        return ['performer']
      }

      return []
    }
  },
  actions: {
    async login({ commit }, userData) {
      return new Promise((resolve, reject) => {
        // The Promise used for router redirect in login
        commit('AUTH_REQUEST')

        const backendData = {
          grant_type: 'password',
          // client_secret: impor.VUE_APP_API_OAUTH_SECRET,
          // client_id: process.env.VUE_APP_API_OAUTH_CLIENT
        }

        const payload = { ...userData, ...backendData }

        axios
          .post(api.default.auth.login, payload)
          .then((response) => {
            commit('AUTH_SUCCESS', response.data)
            resolve(response)
          })
          .catch((error) => {
            commit('AUTH_ERROR', error)
            commit('AUTH_LOGOUT')
            reject(error)
          })
      })
    },
    async logout({ commit }) {
      return new Promise((resolve) => {
        commit('AUTH_LOGOUT')
        resolve()
      })
    },
    async fetchMe({ commit }) {
      return new Promise((resolve) => {
        axios.post(api.default.auth.fetch_me).then((response) => {
          commit('I_FETCHED', response.data)
          resolve(response)
        })
      })
    },
    async refreshToken({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.tokenRefreshOngoing) {
          reject('already ongoing')
        }
        commit('REFRESH_PROGRESS')

        axios
          .post(api.default.auth.refresh)
          .then((response) => {
            commit('AUTH_SUCCESS', response.data)
            Vue.bus.emit('tokenRefreshFinished', { success: true })
            resolve(response)
          })
          .catch((error) => {
            commit('AUTH_ERROR', error)
            commit('AUTH_LOGOUT')
            Vue.bus.emit('tokenRefreshFinished', { success: false })
            reject(error)
          })
          .finally(() => {
            commit('REFRESH_STOP_PROGRESS')
          })
      })
    },
    async register({ commit }) {
      return new Promise((resolve) => {
        axios.post(api.default.auth.register).then((response) => {
          commit('REGISTERED', response.data)
          resolve(response)
        })
      })
    },
    async update({ commit, state }, userData) {
      return new Promise((resolve, reject) => {
        const payload = { ...state.me, ...userData }
        axios
          .put(api.default.profile.auth.update, payload)
          .then((response) => {
            commit('I_FETCHED', response.data)
            resolve(response)
          })
          .catch((e) => reject(e))
      })
    },
    async passwordResetRequest({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(api.default.auth.password_reset_request, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  mutations: {
    AUTH_REQUEST: (state) => {
      state.status = 'loading'
    },
    AUTH_SUCCESS: (state, data) => {
      localStorage.setItem('accessToken', data.access_token)
      state.accessToken = data.access_token
      state.status = 'authenticated'
    },
    AUTH_LOGOUT: (state) => {
      localStorage.removeItem('accessToken')
      state.accessToken = ''
      state.status = 'unauthenticated'
      state.me = JSON.stringify({})
      localStorage.setItem('me', state.me)
    },
    AUTH_ERROR: (state) => {
      state.status = 'error'
    },
    I_FETCHED: (state, data) => {
      localStorage.setItem('me', JSON.stringify(data))
      state.me = data
    },
    REFRESH_PROGRESS: (state) => {
      state.tokenRefreshOngoing = true
    },
    REFRESH_STOP_PROGRESS: (state) => {
      state.tokenRefreshOngoing = false
    }
  }
}
