import axios from '../../axios.js'
import * as api from '../../api'

export default {
  namespaced: true,
  state: {
    settings: {
      defaultColor: 'white',
      colors: [
        {
          income: {
            min: 0,
            max: 300
          },
          title: '0 - 300',
          color: '#525252'
        },
        {
          income: {
            min: 301,
            max: 499
          },
          title: 300,
          color: '#7F1D1D'
        },
        {
          income: {
            min: 500,
            max: 699
          },
          title: 500,
          color: '#991B1B'
        },
        {
          income: {
            min: 700,
            max: 999
          },
          title: 700,
          color: '#B91C1C'
        },
        {
          income: {
            min: 1000,
            max: 1299
          },
          title: 1000,
          color: '#EA580C'
        },
        {
          income: {
            min: 1300,
            max: 1599
          },
          title: 1300,
          color: '#F59E0B'
        },
        {
          income: {
            min: 1600,
            max: 1999
          },
          title: 1600,
          color: '#FACC15'
        },
        {
          title: 2000,
          income: {
            min: 2000,
            max: 2499
          },
          color: '#84CC16'
        },
        {
          title: 2500,
          income: {
            min: 2500,
            max: 2999
          },
          color: '#65A30D'
        },
        {
          title: 3000,
          income: {
            min: 3000,
            max: 3999
          },
          color: '#16A34A'
        },
        {
          title: 4000,
          income: {
            min: 4000,
            max: 4999
          },
          color: '#15803D'
        },
        {
          title: 5000,
          income: {
            min: 5000,
            max: 6999
          },
          color: '#14532D'
        },
        {
          title: 7000,
          income: {
            min: 7000,
            max: 100000
          },
          color: '#C026D3'
        }
      ]
    }
  },
  getters: {
    settings: (state) => state.settings
  }
}
