export default {
  auth: {
    login: '/api/auth/login',
    fetch_me: '/api/auth/me',
    logout: '/api/auth/logout',
    refresh: '/api/auth/refresh',
    password_reset_request: '/api/auth/send-reset-password-link',
    password_reset_update: '/api/auth/reset-password',
    setup_password: '/api/auth/setup-password'
  },
  period: {
    all: '/api/periods/all',
    current: {
      dates: '/api/periods/current/dates',
      table: '/api/periods/current/table'
    },
    dates: (period_id) => `/api/periods/${period_id}/dates`,
    table: (period_id) => `/api/periods/${period_id}/table`,
    refresh: (period_id) => `/api/periods/${period_id}/refresh`
  },
  users: {
    fetchList: '/api/v1/users',
    fetchSingle: (user_id) => `/api/v1/users/${user_id}`,
    create: '/api/users',
    all: '/api/users/all',
  },
  categories: {
    fetchList: '/api/v1/categories',
    fetchSingle: (id) => `/api/v1/categories/${id}`,
    create: '/api/categories',
    update: (id) => `/api/categories/${id}`,
    delete: (id) => `/api/categories/${id}`,
    reorder: '/api/categories/reorder',
    performerPastCategories: (id) => `/api/performer/${id}/categories-past`
  },
  salary: {
    performerPeriod: (id) => `/api/periods/${id}/salary`,
    postSalary: '/api/salary',
    putSalary: (id) => `/api/salary/${id}`,
    sendSalarySheet: (id) => `/api/salary/${id}/send_salary_sheet`,
    updatePaidAtDate: (id) => `/api/salary/${id}/paid_at`
  },
  files: {
    upload: '/api/files/upload',
    uploadCancel: (id) => `/api/files/${id}/upload-cancel`,
    list: '/api/files',
    delete: (id) => `/api/files/${id}/delete`,
    recover: (id) => `/api/files/${id}/recover`,
    deleteForce: (id) => `/api/files/${id}/delete-force`,
  },
  tasks: {
    list: '/api/tasks',
    create: '/api/tasks',
    update: (id) => `/api/tasks/${id}`,
    delete: (id) => `/api/tasks/${id}/delete`,
    reorder: '/api/tasks/reorder',
  },
}
